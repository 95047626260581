/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px; /* Adjust as needed */
    max-height: 80vh; /* Make the modal scrollable if content exceeds this height */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slide-down 0.3s ease-out; /* Add animation */
}

/* Variant Size Group */
.variant-size-group {
    margin-bottom: 20px; /* Add space between size groups */
}

    .variant-size-group h4 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
        text-align: left;
        margin-top: 0px;
    }

    .variant-size-group h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
        text-align: left;
    }

/* Variant Options */
.variant-option {
    margin-bottom: 10px; /* Add space between variant options */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

    .variant-option label {
        margin-left: 10px;
        font-size: 14px;
        color: #333;
        display: flex;
        align-items: center;
    }

    .variant-option input[type="radio"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ddd;
        accent-color: seagreen; /* For modern browsers to style the selected state */
        transition: border-color 0.3s, background-color 0.3s;
        cursor: pointer;
    }

        .variant-option input[type="radio"]:checked {
            border-color: seagreen;
            background-color: seagreen;
            transition: background-color 0.3s, border-color 0.3s;
        }

/* Modal Buttons */
.modal-buttons {
    margin-top: 20px;
}

.modal-button {
    padding: 12px 24px;
    border: none;
    border-radius: 12px; /* More rounded corners */
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

    .modal-button.confirm {
        background-color: seagreen;
        color: white;
    }

        .modal-button.confirm:hover {
            background-color: darkgreen;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

    .modal-button.cancel {
        background-color: #ddd;
        color: black;
    }

        .modal-button.cancel:hover {
            background-color: #ccc;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

/* Mobile Styles */
@media (max-width: 768px) {
    .modal-content {
        width: calc(100% - 40px); /* Ensure padding on mobile */
        max-width: none; /* Allow full width usage on smaller screens */
        margin: 20px; /* Ensure padding on all sides */
    }
}

/* Animation */
@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
