.update-menu-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
}

.menu-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menu-list-container, .add-item-form-container {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.menu-list-container h2, .add-item-form-container h2 {
    margin-bottom: 15px;
}

.menu-list {
    list-style: none;
    padding: 0;
}

.menu-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.menu-item button {
    margin-right: 10px;
}

.add-item-form-container, .edit-item-container {
    margin-top: 20px;
}

.add-item-form, .edit-item-form, .add-variant-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.edit-item-container, .edit-variant-container {
    margin-top: 20px;
}

.variants-container {
    margin-top: 20px;
}

.variant-item {
    margin-bottom: 10px;
}

.edit-variant-container {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
}

.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.confirm-modal-content button {
    margin: 0 10px;
}
