.section {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    padding: 10px; /* Increased padding for more space */
    background-color: #f9f9f9; /* Light background color for better readability */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px; /* Rounded corners for a softer look */
    font-family: 'Arial', sans-serif; /* Clean font */
}

.intro {
    text-align: center; /* Center text */
    font-size: 16px; /* Increase font size for readability */
    color: #333; /* Darker text color */
}

.highlight {
    font-weight: bold;
    color: #228B22; /* Forest green color to emphasize vegetarian */
    background-color: #d4edda; /* Light green background for contrast */
    padding: 0 5px; /* Padding around highlighted text */
    border-radius: 4px; /* Rounded corners for the highlight background */
    font-size: 12px; /* Slightly larger font size for emphasis */
}

/* Button styling - adjust as needed */
.section button {
    margin: 0 8px;
    padding: 10px 20px;
    background-color: white;
    color: grey;
    border: solid;
    border-block-color: lightgrey;
    border-color: lightslategray;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px; /* Slightly larger font size */
    transition: background-color 0.3s, transform 0.2s;
}

.section button:hover {
    background-color: seagreen;
    transform: translateY(-2px);
}

.section button:active {
    background-color: #4d4d4d;
    transform: translateY(0);
}

@media (max-width: 600px) {
    .intro {
        font-size: 12px; /* decrease font size for readability */
    }
}