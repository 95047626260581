.order-confirmation {
    padding: 20px;
    padding-top: 120px;
    max-width: 800px;
    margin: auto;
}

    .order-confirmation h2 {
        text-align: center;
    }

    .order-confirmation h3 {
        margin-top: 20px;
        text-align: center;
    }

    .order-confirmation ul {
        list-style-type: none;
        padding: 0;
    }

    .order-confirmation li {
        padding: 5px 0;
    }
