/* Ensure the modal has relative positioning */
.registration-signin-modal {
    position: relative;
    /* other styles for your modal */
}

/* Style for the close button */
.registration-signin-modal .close-modal {
    position: absolute; /* Absolute positioning */
    top: 10px; /* Distance from the top of the modal */
    right: 10px; /* Distance from the right side of the modal */
    background-color: #fff; /* Background color of the button */
    border: none; /* Remove default border */
    border-radius: 50%; /* Round shape */
    width: 30px; /* Fixed width of the button */
    height: 30px; /* Fixed height of the button */
    display: flex; /* Center the content */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
    z-index: 1000; /* Ensure it is above other content */
    padding: 0; /* Remove default padding */
    font-family: inherit; /* Ensure font is consistent */
    font-size: 16px; /* Adjust font size as needed */
    color: #333; /* Color of the text/icon */
}

/* Optional: Style for the close button’s text/icon */
.registration-signin-modal .close-modal::before {
    content: '×'; /* Content of the button */
    font-size: 16px; /* Size of the icon */
}

/* Modal overlay styles */
.registration-signin-modal .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's below the modal but above other content */
}

/* Modal content styles */
.registration-signin-modal .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    position: relative; /* Ensure close button can be positioned absolutely */
}

/* Centered modal options buttons */
.registration-signin-modal .modal-options {
    text-align: center;
}

.registration-signin-modal .modal-options button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.registration-signin-modal .modal-options button:hover {
    background: #0056b3;
}

/* Styles for modal form */
.registration-signin-modal .modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Password container styles */
.registration-signin-modal .password-container {
    position: relative;
    width: 100%;
    margin-bottom: 15px; /* Adjust spacing as needed */
}

.registration-signin-modal .password-container input {
    padding-right: 50px; /* Space for the toggle button */
    width: 100%; /* Full width */
}

/* Toggle password button styles */
.registration-signin-modal .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
}

.registration-signin-modal .toggle-password:hover {
    color: #0056b3;
}

/* Register and sign-in button styles */
.registration-signin-modal .register-btn, 
.registration-signin-modal .sign-in-btn {
    background-color: #28a745; /* Green background for register and sign-in buttons */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    text-align: center;
}

.registration-signin-modal .register-btn:hover, 
.registration-signin-modal .sign-in-btn:hover {
    background-color: seagreen;
}

/* Error message styles */
.registration-signin-modal .error-message {
    color: red;
    margin-bottom: 15px;
}

/* Register and sign-in link styles */
.registration-signin-modal .register-link, 
.registration-signin-modal .sign-in-link {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

.registration-signin-modal .register-link a, 
.registration-signin-modal .sign-in-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.registration-signin-modal .register-link a:hover, 
.registration-signin-modal .sign-in-link a:hover {
    text-decoration: underline;
}

/* Registration info box styles */
.registration-signin-modal .registration-info {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.registration-signin-modal .registration-info p {
    margin: 0 0 10px;
    font-size: 1em;
    color: #333;
}

.registration-signin-modal .registration-info strong {
    color: #007bff;
}

/* Register and Sign-In Button Styles */
.registration-signin-modal .register-btn, 
.registration-signin-modal .sign-in-btn {
    background-color: #28a745; /* Green background */
    color: white;
    border: none;
    border-radius: 8px; /* Slightly more rounded corners */
    font-size: 18px; /* Larger font size */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for background color and scale */
    width: 100%;
    max-width: 220px; /* Slightly larger button width */
    padding: 15px; /* Increased padding for a larger button */
    text-align: center;
    margin: 10px 10px; /* Center align buttons with margin */
}

.registration-signin-modal .register-btn:hover, 
.registration-signin-modal .sign-in-btn:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Media Queries for Smaller Screens */
@media (max-width: 600px) {
    .registration-signin-modal .modal-content {
        padding: 15px;
    }

    .registration-signin-modal .password-container input {
        font-size: 14px;
        padding: 8px;
    }

    .registration-signin-modal .register-btn, 
    .registration-signin-modal .sign-in-btn {
        font-size: 14px;
        padding: 8px;
    }
}
