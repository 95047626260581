/* Navbar container */
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
}

/* Navbar logo section */
.navbar-logo-container {
    display: flex;
    align-items: center;
}

.navbar-logo-container img {
    height: 40px;
    margin-right: 10px;
    padding-top: 5px;
}

.navbar-title {
    color: black;
    font-size: 1.5rem;
}

/* Navbar links section */
.navbar-links-container {
    display: flex;
    align-items: center;
}

.navbar-links-container a {
    color: black;
    margin: 0 10px;
    text-decoration: none;
}

.navbar-links-container a:hover {
    text-decoration: underline;
}

/* Navbar actions section */
.navbar-actions-container {
    display: flex;
    align-items: center;
}

/* Sign in button */
.sign-in-button {
    background-color: rgb(210, 173, 51);
    color: black;
    padding: 5px 15px 5px;
    cursor: pointer;
    margin-right: 0;
    border-radius: 8px;
    font-size: 0.8rem; /* Default font size */
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
}

/* Basket icon */
.basket-icon-container {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 10px;
    cursor: pointer;
}

.basket-icon-container img {
    height: 20px;
    width: 20px;
}

/* Basket count */
.basket-count-text {
    background-color: white;
    color: black;
    border-radius: 50%;
    padding: 5px 10px;
    margin-right: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar-links-container {
        display: none;
    }

    .navbar-container {
        padding: 10px;
    }

    .navbar-logo-container img {
        height: 30px;
        margin-right: 5px;
    }

    .navbar-title {
        font-size: 1rem;
    }

    .navbar-actions-container {
        flex-direction: row; /* Stack items vertically on smaller screens */
    }

    .sign-in-button {
        font-size: 0.6rem; /* Smaller font size on smaller screens */
    }

    .basket-count-text {
        font-size: 0.8rem; /* Smaller font size on smaller screens */
    }
}
