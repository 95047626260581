/* Base styles for the notification banner */
.notification-banner {
    position: fixed; /* Fix the position to avoid overlapping */
    top: 0; /* Position it at the top */
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center;
    color: white;
    z-index: 999; /* Ensure it is above other content */
}

/* Styles for ordering hours */
.banner-green {
    background-color: green; /* Green for within ordering hours */
}

/* Styles for outside ordering hours */
.banner-red {
    background-color: rgb(230, 53, 53); /* Light red for outside ordering hours */
    font:bold;
}

/* Adjust positioning below the carousel */
.banner-space {
    margin-top: 60px; /* Adjust based on the height of the carousel */
}

.track-order-container {
    margin-top: 0px;
    background-color: white;
    border-radius: 8px;
    color: black;
}

.wording-container{
    margin-bottom: 10px;
    margin-top: 15px;
    background-color: white;
    border-radius: 8px;
    color: black;
    font-size: 12px;
    font-weight: 700;
}

.ios-button-track {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
}

.ios-button-track:hover {
    background-color: #218838;
}

/* Media Queries for Smaller Screens */
@media (max-width: 600px) {
    .notification-banner {
        font-size: 11px;
    }
}

