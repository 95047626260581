/* Scoped Styles for Checkout Page */
.checkout-page {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
}

.checkout-page h2 {
    margin-bottom: 20px;
}

.checkout-page .basket-summary {
    margin-bottom: 20px;
}

.checkout-page .basket-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.checkout-page .basket-item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    flex: 1 1 100%;
}

.checkout-page .item-detail {
    display: flex;
    align-items: center;
    width: 100%;
}

.checkout-page .item-image {
    margin-right: 15px;
}

.checkout-page .item-image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.checkout-page .item-info {
    flex: 1;
    margin-right: 15px;
}

.checkout-page .item-quantity {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.checkout-page .item-quantity button {
    padding: 5px 10px;
    margin: 0 5px;
}

.checkout-page .remove-item {
    background: none;
    color: darkred;
    border: none;
    padding: 0 10px;
    cursor: pointer;
}

.checkout-page .remove-item:hover {
    background-color: #c82333;
}

.checkout-page .align-right {
    text-align: left;
    margin-top: 20px;
}

.checkout-page .ios-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-page .ios-button:hover {
    background-color: #0056b3;
}

.checkout-page .total-value {
    font-weight: bold;
    color: forestgreen;
    padding-left: 5px;
}

.checkout-page .ios-button-select {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-page .ios-button-select:hover {
    background-color: #218838;
}

.checkout-page .cancel-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #f44336;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.checkout-page .cancel-button:hover {
    background-color: #e53935;
}

/* Default state for address-select */
.checkout-page .address-select {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #b13d49; /* Default background color */
    color: white; /* Default text color */
    border: 1px solid transparent; /* Border to transition smoothly */
    border-radius: 4px; /* Rounded corners */
    padding: 10px; /* Padding inside the element */
}

/* Selected state for address-select */
.checkout-page .address-select.selected {
    background-color: white; /* Background color when selected */
    color: black; /* Text color when selected */
}

.checkout-page .contact-details {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.checkout-page select, .checkout-page input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    color: #495057;
    background-color: #fff;
}

.checkout-page select:focus, .checkout-page input:focus {
    border-color: #007bff;
    outline: none;
}

.checkout-page .contact-details .contact-person,
.checkout-page .contact-details .contact-number {
    margin-bottom: 20px;
}

.checkout-page .input {
    color: red;
    font-size: 0.9em;
}

.checkout-page .loading-icon {
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 50%;
    border-top: 2px solid #fff;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.checkout-page .wide-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 40px; /* Increased padding for wider button */
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Adjust font size if needed */
    width: 100%;
}

.checkout-page .wide-button:hover {
    background-color: #218838;
}

.checkout-page .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.checkout-page .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.checkout-page .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.checkout-page .modal-header h4 {
    margin: 0;
}

.checkout-page .modal-header button {
    background: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.checkout-page .modal-body {
    padding: 20px 0;
}

.checkout-page .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.checkout-page .modal-footer button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.checkout-page .modal-footer .confirm-btn {
    background-color: #28a745;
    color: white;
}

.checkout-page .modal-footer .cancel-btn {
    background-color: #dc3545;
    color: white;
}

.checkout-page .modal-footer .confirm-btn:hover {
    background-color: #218838;
}

.checkout-page .modal-footer .cancel-btn:hover {
    background-color: #c82333;
}

/* Delivery Time Options Styles */
.checkout-page .delivery-time-options {
    margin-top: 20px; /* Space above the delivery options */
    margin-bottom: 20px; /* Space above the delivery options */
    background-color: #f9f9f9; /* Light background for clarity */
    border: 1px solid #ddd; /* Light border for definition */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding inside the options box */
    text-align: left; /* Aligns text to the left */
}

.checkout-page .delivery-time-options h4 {
    margin-bottom: 15px; /* Space below the heading */
}

.checkout-page .delivery-time-options label {
    display: block; /* Block display for labels to stack */
    margin-bottom: 10px; /* Space between labels */
    cursor: pointer; /* Pointer cursor for better UX */
    text-align: left; /* Ensure text aligns to the left */
}

/* Specific Styles for Radio Buttons */
.checkout-page .delivery-time-options input[type="radio"].delivery-time-radio {
    width: auto; /* Make sure radio buttons are not affected by the width rule */
    margin-right: 10px; /* Space between radio button and label */
    vertical-align: middle; /* Aligns radio buttons with text */
    cursor: pointer; /* Pointer cursor for better UX */
}

.checkout-page .delivery-time-options select {
    margin-top: 10px; /* Space above the select dropdown */
    width: 100%; /* Make the dropdown full width */
}

.checkout-page .delivery-time-options p {
    color: #dc3545; /* Color for messages about unavailable slots */
    font-size: 0.9em; /* Slightly smaller text for messages */
    margin-top: 10px; /* Space above the message */
}
