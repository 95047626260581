/* src/components/TermsAndConditions.css */
.terms-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

header {
    background-color: #f4f4f4;
    padding: 1em;
    text-align: center;
}

h1 {
    margin: 0;
}

main {
    padding: 2em;
}

footer {
    background-color: #f4f4f4;
    padding: 1em;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
}
