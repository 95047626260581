/* ContactUs.css */

.contact-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-us h2 {
    font-size: 2em;
    color: #2e7d32; /* Earthy green color for a fresh vibe */
    text-align: center;
    margin-bottom: 1.5em;
}

.contact-us section {
    padding: 10px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
}

.contact-us section p {
    font-size: 1.1em;
    line-height: 1.6em;
    margin-bottom: 1.5em;
}

.contact-us form {
    display: flex;
    flex-direction: column;
}

.contact-us input, 
.contact-us textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

/* Google Rate Us Section */
.rate-us {
    margin-top: 30px;
    text-align: center;
}

.rate-us p {
    font-size: 16px;
    margin-bottom: 15px;
    color: #555;
}

/* Google Rate Button Styling */
.google-rate-btn {
    display: inline-block;
    background-color: green; /* Google Blue */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .contact-us {
        padding: 15px;
    }

    .contact-us h2 {
        font-size: 1.8em;
    }

    .contact-us section p {
        font-size: 1em;
    }

    .google-rate-btn {
        font-size: 14px;
        padding: 8px 16px;
    }
}
