.frontpagebasket {
    cursor: pointer; /* Make it clear that the basket is clickable */
    transition: background-color 0.3s ease;
    position: fixed;
    top: 80px; /* Original position for desktop screens */
    right: 50px;
    background-color: forestgreen; /* Use a primary button color */
    padding: 15px 15px; /* Adjust padding to be more button-like */
    border-radius: 50px; /* Rounded button shape */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for a button effect */
    color: white; /* White text for contrast */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: pulse 2s infinite; /* Pulse animation added */
}

    .frontpagebasket:hover {
        background-color: #0056b3; /* Darken background on hover */
    }

.frontpagebasket-summary {
    display: flex;
    align-items: center;
    gap: 10px;
}

    .frontpagebasket-summary p {
        margin: 0;
        font-size: 1.2rem;
    }

.frontpagebasket .icon {
    margin-right: 8px; /* Add spacing between the icon and text */
    font-size: 1.2rem; /* Increase icon size */
}

/* Mobile Styles */
@media (max-width: 768px) {
    .frontpagebasket {
        top: auto; /* Remove top positioning */
        right: auto; /* Remove right positioning */
        bottom: 10px; /* Position at the bottom */
        left: 10px; /* Add left padding */
        right: 10px; /* Add right padding */
        padding: 10px 15px; /* Slightly smaller padding for mobile */
        border-radius: 8px; /* Maintain a rounded button shape on mobile */
        display: flex;
        justify-content: center;
    }
}
