/* General styles for the application */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top:40px;
    background-color: white;
}

/* Navbar Styles */
.navbar-container {
    background-color: white;
    color: black; /* Ensure text is visible on the white background */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Full width */
    position: fixed; /* Fixed positioning */
    top: 0; /* Stick to the top */
    left: 0; /* Align with the left edge */
    z-index: 999; /* Ensure it sits above other elements */
    padding: 10px 20px; /* Add padding to ensure spacing inside */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
}

/* Adjust for the space occupied by the fixed navbar */
.content {
    flex: 1; /* Take up remaining space */
    padding: 60px 0 0; /* Top padding should be equal to the height of the navbar */
    background-color: #ffffff;
}

/* Carousel Styles */
.carousel {
    background-color: white;
    height: 200px; /* Adjust as needed */
    padding-top: 50px;
}

/* Footer Styles */
.footer {
    background-color: white;
    color: black;
    text-align: center;
}

    .footer a {
        margin: 0 10px;
        text-decoration: none;
        color: black;
    }

/* Menu Grid */
.menu-grid, .menu-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10%;
    padding-right: 10%;
}

.menu-item {
    width: 100%; /* Full width by default */
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.3s;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    padding: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
}

    .menu-item h3 {
        margin: 0;
        font-size: 1.2rem;
        color: #333;
        font-weight: bold;
    }

    .menu-item p {
        margin: 5px 0;
        color: #777;
    }

.menu-item-info {
    flex: 1;
    padding-left: 10px;
    text-align: left;
    align-items: center;
    justify-content: center;
}

.menu-item-image img {
    max-width: 100px;
    max-height: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

/* Menu Header */
.menu h2 {
    margin: 10px;
    font-size: 1.5rem; /* Increase font size */
    color: black;
    font-weight: bold;
    padding: 10px;
    background-color: mintcream; /* Add a background color */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    text-align: center; /* Center the text */
    text-transform: uppercase; /* Make the text uppercase */
}

/* Section Selector */
.section-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

    .section-selector button, .ios-button, .ios-button-select {
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        color: black;
        border: none;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        /*transition: background-color 0.1s, box-shadow 0.1s;*/
        cursor: pointer;
        outline: none;
    }

    .section-selector button {
        background-color: white;
        color: black;
    }

        .section-selector button:hover, .ios-button:hover {
            background-color: seagreen;
        }

.ios-button-select {
    background-color: seagreen;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        align-items: center;
        padding: 10px;
    }

    .navbar-links {
        flex-direction: column;
        gap: 5px;
    }

    .menu-grid, .menu-container {
        flex-direction: column;
        gap: 10px;
        padding-left: 0%;
        padding-right: 0%;
    }

    .menu-item {
        flex: 1 1 100%;
        width: auto;
    }
}

/* Desktop Styles using Grid */
@media (min-width: 769px) {
    .menu-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        gap: 20px; /* Adjust gap as needed */
    }

    .menu-item {
        width: 100%;
        padding: 10px; /* Remove margin, handled by grid gap */
    }
}
