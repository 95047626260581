/* ConfirmationModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    position: relative; /* Needed for absolute positioning of the close button */
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.modal-buttons {
    margin-top: 20px;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
}

    .modal-button.confirm {
        background-color: seagreen;
        color: white;
    }

        .modal-button.confirm:hover {
            background-color: darkgreen;
        }

    .modal-button.cancel {
        background-color: #ddd;
        color: black;
    }

        .modal-button.cancel:hover {
            background-color: #ccc;
        }

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px; /* Size of the close icon */
    cursor: pointer;
    color: #000; /* Color of the close icon */
    line-height: 1;
}

    .close-button:hover {
        color: #f00; /* Change color on hover */
    }

h3 {
    margin-top: 0;
}
