.hamburger-menu {
    position: relative;
    z-index: 1000; /* Ensures the menu stays on top */
}

.hamburger-icon {
    cursor: pointer;
    width: 30px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

    .hamburger-icon .line {
        background-color: #333; /* Darker for better contrast */
        height: 3px;
        border-radius: 2px;
        transition: transform 0.3s ease, background-color 0.3s ease;
    }

    .hamburger-icon.open .line:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger-icon.open .line:nth-child(2) {
        opacity: 0;
    }

    .hamburger-icon.open .line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }

.dropdown {
    position: absolute;
    top: 80px;
    left: 0; /* Align dropdown with the hamburger icon */
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    padding: 10px;
    min-width: 150px; /* Ensure the dropdown has a decent width */
}

    .dropdown a, .dropdown span {
        display: block;
        padding: 10px 20px; /* Add more padding for better touch targets */
        text-decoration: none;
        color: #333; /* Darker text for better readability */
        font-weight: 500; /* Slightly bolder text for modern look */
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

        .dropdown a:hover, .dropdown span:hover {
            background-color: #f5f5f5;
            color: #007bff; /* Add some color on hover */
        }

        .dropdown a:active, .dropdown span:active {
            background-color: #e0e0e0; /* Slightly darker for active state */
        }
