/* AboutUs.css */

.about-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.about-us h2 {
    font-size: 2em;
    color: #2e7d32; /* Earthy green color for a fresh vibe */
    text-align: center;
    margin-bottom: 1.5em;
}

.about-us section p {
    font-size: 1.1em;
    line-height: 1.6em;
    margin-bottom: 1.5em;
    text-align: justify;
}

.about-us section p:last-child {
    margin-bottom: 0;
}

.about-us section p:nth-last-child(2) {
    background-color: #f1f8e9;
    padding: 15px;
    border-left: 4px solid #2e7d32;
    font-style: italic;
    border-radius: 6px;
}

.about-us section {
    padding: 10px 20px;
    background-color: #ffffff;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .about-us {
        padding: 15px;
    }

    .about-us h2 {
        font-size: 1.8em;
    }

    .about-us section p {
        font-size: 1em;
    }
}
