.desc {
    padding-right:10px;
}

/* Optional: Text color adjustments */
.warning-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.variant-option {
    padding: 5px;
    margin: 5px 0;
    border-radius: 4px;
}


/* Styling for the specials category */
.menu#specials {
    background-color: yellow; /* Light gray background for specials */
    border: 3px solid red; /* Highlight border for specials */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding around the content */
    margin-bottom: 20px; /* Space below the specials section */
}

.menu#specials h2 {
    color: red; /* Yellow color for the header */
    background-color: white; /* Yellow color for the header */
    font-size: 26px;
}

/* Optional: Text color adjustments */
.menu#specials .menu-item-info {
    font-size: 16px;
}

.menu#specials .desc {
    color: #666; /* Slightly lighter text color for descriptions */
}

.ios-button {
    background-color: greenyellow;
    border-color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.2s;
    /* animation: pulse 1s ease-in-out infinite; */
}

.ios-button:hover {
    background-color: #28a745;
}

 .ios-button-select {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.ios-button-select:hover {
    background-color: #218838;
}

.menu-header {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap; /* Prevent buttons from wrapping */
    overflow-x: auto; /* Allow horizontal scroll if buttons overflow */
}

.menu-header button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: 16px; /* Default font size for larger screens */
    flex-shrink: 1; /* Allow buttons to shrink to fit */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

/* Define the keyframes for the animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.ios-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 768px) {
    .menu#specials .menu-item-info {
        font-size: 12px;
    }

    .menu#specials h2 {
        font-size: 18px;
    }
    
    .menu .menu-item-info {
        font-size: 12px;
    }

    .menu-header button {
        width: 80px; /* Smaller width for mobile */
        height: 40px; /* Smaller height for mobile */
        font-size: 12px; /* Reduce font size on mobile */
    }
}