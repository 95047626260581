/* DeliveryMethodModal.css */
.delivery-method-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.delivery-method-modal .modal-content {
    position: relative; /* Position relative for absolute positioning of close button */
    background: white;
    padding: 20px;
    padding-top: 50px; /* Ensure enough space for the close button */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

.delivery-method-modal .ios-button {
    background-color: seagreen;
}

/* 
.delivery-method-modal  .modal-options button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

    .delivery-method-modal .close-button:hover {
        color: #f00;  Change color on hover 
    } */

/* Style for the close button */
.delivery-method-modal .close-modal-2 {
    position: absolute; /* Absolute positioning */
    top: 10px; /* Distance from the top of the modal */
    right: 10px; /* Distance from the right side of the modal */
    background-color: #fff; /* Background color of the button */
    border: none; /* Remove default border */
    border-radius: 50%; /* Round shape */
    width: 30px; /* Fixed width of the button */
    height: 30px; /* Fixed height of the button */
    display: flex; /* Center the content */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
    z-index: 1000; /* Ensure it is above other content */
    padding: 0; /* Remove default padding */
    font-family: inherit; /* Ensure font is consistent */
    font-size: 16px; /* Adjust font size as needed */
    color: #333; /* Color of the text/icon */
}

.delivery-method-modal .ios-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.delivery-method-modal .ios-button .coming-soon {
    font-size: 12px;
    color: #888;
}

/* Add spacing between buttons */
.delivery-method-modal .modal-content button {
    display: block;
    margin: 10px auto; /* Center buttons and add spacing */
    max-width: 300px; /* Optional: set a max-width */
}

.delivery-method-modal .loading-icon {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

