.driver-orders {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.driver-selection {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.driver-selection label {
    margin-right: 10px;
    font-weight: bold;
}

.driver-selection select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.order-card {
    background: white;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-card p {
    margin: 5px 0;
}

.order-actions {
    margin-top: 10px;
}

.order-actions select,
.order-actions input,
.order-actions textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.order-actions button {
    margin-right: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.order-actions button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.order-actions textarea {
    height: 100px;
    resize: vertical;
}

/* New styles for the toggle button */
.toggle-button {
    padding: 10px 15px;
    background-color: #28a745; /* Green color for the button */
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
}

.toggle-button:hover {
    background-color: #218838; /* Darker green on hover */
}

.toggle-button:focus {
    outline: none; /* Remove default outline */
}
