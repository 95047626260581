/* Register.css */
.register-container {
    max-width: 400px; /* Match SignInForm width */
    margin: 0 auto; /* Center the form */
    padding: 20px; /* Match SignInForm padding */
    background-color: #f9f9f9; /* Match SignInForm background */
    border-radius: 8px; /* Match SignInForm border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Match SignInForm box shadow */
}

    .register-container h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }

    .register-container form {
        display: flex;
        flex-direction: column;
    }

    .register-container input[type="text"],
    .register-container input[type="password"],
    .register-container input[type="email"], /* Include email input if needed */
    .register-container input[type="number"] { /* Include number input if needed */
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        width: 100%; /* Ensure full width */
        box-sizing: border-box;
    }

        .register-container input[type="text"]:focus,
        .register-container input[type="password"]:focus,
        .register-container input[type="email"]:focus,
        .register-container input[type="number"]:focus {
            border-color: #007bff;
            outline: none;
        }

.password-container {
    position: relative;
    width: 100%; /* Ensure container takes full width */
}

    .password-container input {
        padding-right: 40px; /* Space for the toggle button */
        width: 100%; /* Ensure input field takes full width */
        box-sizing: border-box; /* Include padding in width calculation */
    }

.toggle-password {
    position: absolute;
    right: 10px; /* Adjust this value if necessary */
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px; /* Adjust font size if necessary */
    padding: 0;
    padding-bottom: 10px;
    z-index: 10; /* Ensure the button is above the input */
}

.register-btn {
    background-color: #28a745; /* Match SignInForm button color */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Make button full width of the form */
    max-width: 200px; /* Constrain button width */
    align-self: center; /* Center the button */
    padding: 10px; /* Match padding with SignInForm */
    margin-bottom: 10px; /* Space between buttons */
}

    .register-btn:hover {
        background-color: seagreen; /* Match SignInForm hover color */
    }

.cancel-btn {
    max-width: 200px; 
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Make button full width of the form */
    max-width: 200px; /* Constrain button width */
    align-self: center; /* Center the button */
    padding: 10px; /* Match padding with SignInForm */
    margin-bottom: 10px; /* Space between buttons */
}

.error-message {
    color: red;
    margin-bottom: 15px; /* Match spacing with SignInForm */
}

.register-link {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

    .register-link a {
        color: #007bff;
        text-decoration: none;
        font-weight: bold;
    }

        .register-link a:hover {
            text-decoration: underline;
        }

/* Media Queries for Smaller Screens */
@media (max-width: 600px) {
    .register-container {
        padding: 15px;
    }

        .register-container input[type="text"],
        .register-container input[type="password"],
        .register-container input[type="email"],
        .register-container input[type="number"] {
            font-size: 14px;
            padding: 8px;
        }

    .register-btn {
        font-size: 14px;
        padding: 8px;
    }
}
