.carousel {
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
}

.carousel-inner {
    display: flex;
    transition: transform 1s ease-in-out;
}

.carousel-slide {
    height: 200px;
    opacity: 1; /* Ensure all slides are visible */
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

@media (min-width: 1024px) {
    .carousel.large-screen .carousel-inner {
        display: flex;
    }
}
