.kitchen-orders {
    padding: 20px;
}

.status-heading {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
}

.order-section {
    margin-bottom: 30px;
}

.order-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0;
    list-style-type: none;
}

.order-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid grey;
    box-sizing: border-box;
}

    .order-item p {
        margin: 10px 0;
        font-size: 1rem;
        color: #333;
    }

    .order-item select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
        margin-top: 10px;
        box-sizing: border-box;
    }

        .order-item select:focus {
            outline: none;
            border-color: seagreen;
        }


    .order-item strong {
        font-weight: bold;
    }

    .order-item button {
        background-color: seagreen;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        margin-top: 10px;
        transition: background-color 0.3s;
    }

        .order-item button:hover {
            background-color: darkgreen;
        }

.error-message {
    color: red;
    font-weight: bold;
}
