.sign-in-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .sign-in-container h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }

    .sign-in-container form {
        display: flex;
        flex-direction: column;
    }

    .sign-in-container input[type="text"],
    .sign-in-container input[type="password"] {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        width: 100%; /* Ensure the input field takes full width */
        box-sizing: border-box;
    }

        .sign-in-container input[type="text"]:focus,
        .sign-in-container input[type="password"]:focus {
            border-color: #007bff;
            outline: none;
        }

.password-container {
    position: relative;
    width: 100%; /* Ensure container takes full width */
}

    .password-container input {
        padding-right: 40px; /* Space for the toggle button */
        width: 100%; /* Ensure input field takes full width */
        box-sizing: border-box; /* Include padding in width calculation */
    }

.toggle-password {
    position: absolute;
    right: 10px; /* Adjust this value if necessary */
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 10px; /* Adjust font size if necessary */
    padding: 0;
    padding-bottom:10px;
    z-index: 10; /* Ensure the button is above the input */
}

.sign-in-container button[type="submit"],
.sign-in-container .cancel-btn {
    background-color: #28a745; /* Green background for sign-in */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Full width to match input fields */
    max-width: 200px; /* Consistent max-width */
    align-self: center;
    padding: 10px; /* Consistent padding */
    margin-bottom: 10px; /* Space between buttons */
}

    .sign-in-container button[type="submit"]:hover {
        background-color: seagreen;
    }

.sign-in-container .cancel-btn {
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    border: 1px solid #ddd; /* Light border */
}

    .sign-in-container .cancel-btn:hover {
        background-color: #e0e0e0; /* Slightly darker grey on hover */
        color: #000; /* Darker text color on hover */
        border-color: #bbb; /* Darker border color on hover */
    }

    .sign-in-container .cancel-btn:focus {
        outline: none; /* Remove default outline */
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Blue outline for focus */
    }

.error-message {
    color: red;
    margin-bottom: 15px;
}

.register-link {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

    .register-link a {
        color: #007bff;
        text-decoration: none;
        font-weight: bold;
    }

        .register-link a:hover {
            text-decoration: underline;
        }

.register-btn {
    padding: 5px 20px;
    background-color: lightcoral; /* Button background color */
    color: white; /* Button text color */
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin-left: 5px; /* Adjust margin as needed */
}

.register-btn:hover {
    text-decoration: none; /* Remove underline on hover */
}

/* Media Queries for Smaller Screens */
@media (max-width: 600px) {
    .sign-in-container {
        padding: 15px;
    }

        .sign-in-container input[type="text"],
        .sign-in-container input[type="password"] {
            font-size: 14px;
            padding: 8px;
        }

        .sign-in-container button[type="submit"],
        .sign-in-container .cancel-btn {
            font-size: 14px;
            padding: 8px;
        }
}
