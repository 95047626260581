/* Orders.css */

.orders-page {
    padding: 40px 20px 20px;
}

.order-item {
    margin-bottom: 20px;
}

.order-status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.status-step {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.status-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}

.status-circle.completed {
    background-color: green;
}

.status-label {
    font-size: 14px;
}

.completed-status {
    display: flex;
    align-items: center;
}

.completed-status .status-circle {
    background-color: green;
    color: white;
}

/* Add additional styles for completed orders and status circles */


@media (max-width: 768px) {
    .order-status {
        flex-direction: column;
        align-items: flex-start;
    }
    .status-step {
        margin-bottom: 15px;
    }
    .status-circle {
        margin-bottom: 5px;
    }
}
